// @flow
import type { Node } from "react";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { variableSliceAdjacentSliceRules } from "../slices/AdjacentSliceRules/AdjacentSliceRules";

const backgroundStyle = css`
  background-color: ${({ darkMode, theme }) =>
    darkMode ? theme.palette.hague : "white"};
  ${variableSliceAdjacentSliceRules}
`;

const wrapperStyle = css`
  margin: 0 auto;
  text-align: center;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  ${media.tablet`
    text-align: left;
    max-width: 534px;
  `}
  ${media.desktop`
    flex-direction: ${({ reversed }) => (reversed ? "row-reverse" : "row")};
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    div:${({ reversed }) => (reversed ? "first-child" : "last-child")} {
      margin-right: calc(100% * 1 / 12);
    }
  `};
`;

const leftComponentWrapper = css`
  display: flex;
  align-self: stretch;
  align-items: center;
  ${media.desktop`
    width: calc((100% / 12 * 5 ) - 20px);
    box-sizing: border-box;
  `};
`;

const rightComponentWrapper = css`
  margin-top: 20px;
  ${media.desktop`
    width: calc((100% / 12 * 5 ) - 20px);
    box-sizing: border-box;
    margin-top: 0px;
    & > *:first-child {
      margin-top: 0;
    }
  `};
`;

type Props = {
  leftComponent: Node,
  rightComponent: Node,
  darkMode?: ?boolean,
  reversed?: ?boolean,
  className?: ?string,
};

/*
 * This accepts two components and places them side by side in a slice style container.
 * It's intended as a more flexible version of the half image half text slice
 */

export const HalfAndHalfWrapper = ({
  leftComponent,
  rightComponent,
  darkMode,
  reversed,
  className,
}: Props) => (
  <div
    css={backgroundStyle}
    darkMode={darkMode}
    className={`${className || ""} optional-top-padding ${
      darkMode ? "darkModeOn" : "darkModeOff"
    }`}
  >
    <div reversed={reversed} css={wrapperStyle}>
      <div css={leftComponentWrapper}>{leftComponent}</div>
      <div css={rightComponentWrapper}>{rightComponent}</div>
    </div>
  </div>
);
