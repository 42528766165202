// @flow
import { css } from "styled-components";
import { media } from "../../media";

const standardSlicePadding = css`
  padding: 40px 20px;
  ${media.desktop`
    padding: 80px 20px;
  `}
`;

const standardNoSidePadding = css`
  padding: 40px 0px;
  ${media.desktop`
    padding: 80px 0px;
  `}
`;

/*
 * Styles for slices without a 'Dark mode'
 * - If next sibling has no 'dark mode', reduce padding on next sibling
 * - Otherwise keep standard padding
 */
const adjacentSliceRules = css`
  + .optional-top-padding:not(.darkModeOn) {
    padding-top: 0;
  }

  ${media.desktop`
  + .optional-top-padding:not(.darkModeOn) {
      padding-top: 0;
    }
  `}
`;

export const whiteSliceAdjacentSliceRules = css`
  ${standardSlicePadding}
  ${adjacentSliceRules}
`;

export const whiteSliceAdjacentSliceRulesNoSidePadding = css`
  ${standardNoSidePadding}
  ${adjacentSliceRules}
`;

/*
 * Styles for slices with a 'Dark mode'
 * - If self and next sibling have 'dark mode', reduce padding on next sibling
 * - If neither self nor next sibling have 'dark mode' (are white), reduce padding on next sibling
 * - Otherwise keep standard padding
 */

export const variableSliceAdjacentSliceRules = css`
  ${standardSlicePadding}

  &.darkModeOn + .optional-top-padding.darkModeOn {
    padding-top: 0;
  }
  &.darkModeOff + .optional-top-padding:not(.darkModeOn) {
    padding-top: 0;
  }

  ${media.desktop`
    &.darkModeOn + .optional-top-padding.darkModeOn {
      padding-top: 0;
    }
    &.darkModeOff + .optional-top-padding:not(.darkModeOn) {
      padding-top: 0;
    }
  `};
`;
