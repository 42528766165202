// @flow
import { css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { ButtonLink } from "@nested/component-library";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { mediumParagraph } from "../../components/Typography/Paragraphs";
import {
  largeHeading,
  regularHeading,
  sectionHeading,
} from "../../components/Typography/Headings";
import { HalfAndHalfWrapper } from "../../components/HalfAndHalfWrapper";

const imageStyle = css`
  width: 100%;
`;

const headingStyle = css`
  ${regularHeading}
  white-space: pre-wrap;
  margin: 10px 0 0;
  color: ${({ darkMode, theme }) =>
    darkMode ? theme.palette.sand100 : "inherit"};
  ${media.tablet`
    ${largeHeading}
    max-width: 100%;
    color: ${({ darkMode, theme }) =>
      darkMode ? theme.palette.sand100 : "inherit"};
  `}
  ${media.desktop`
    font-size: 42px;
  `}
`;

const headingLabelStyle = css`
  ${sectionHeading}
  margin: 10px 0 0;
  color: ${({ darkMode, theme }) =>
    darkMode ? theme.palette.sand80 : "inherit"};
`;

const paragraphStyle = css`
  ${mediumParagraph}
  margin-top: 20px;
  margin-bottom: 0px;
  color: ${({ darkMode, theme }) =>
    darkMode ? theme.palette.sand100 : "inherit"};
`;

const buttonStyles = css`
  margin-top: 30px;
  ${media.tablet`
    margin-top: 20px;
`}
  ${media.desktop`
  margin-top: 30px;
`}
`;

const iconWrapperStyle = css`
  display: none;
  ${media.desktop`
    display: block;
  `};
`;

const iconStyle = css`
  width: 100px;
`;

const tickListItem = css`
  &:first-of-type {
    margin-top: 25px;
  }
  display: flex;
  margin: 14px 0;
  text-align: left;
  ${media.desktop`
    width: 100%;
  `}
`;

const tick = css`
  color: ${({ theme }) => theme.palette.green500};
  margin-right: 15px;
`;

type Props = {
  image: PrismicImage,
  icon?: PrismicImage,
  heading: string,
  reversed: "Yes" | "No",
  darkMode: "Yes" | "No",
  buttonText?: string,
  buttonStyle: "accent" | "primary" | "standard",
  buttonLink?: PrismicLink,
  headingLabel?: string,
  multipleParagraphs: PrismicRichText,
  tickList?: PrismicRichText,
};

export const HalfImageHalfText = ({
  image,
  icon,
  heading,
  reversed,
  darkMode,
  buttonText,
  buttonStyle,
  buttonLink,
  headingLabel,
  multipleParagraphs,
  tickList,
}: Props) => {
  const darkModeBool = darkMode === "Yes";
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  return (
    <div ref={ref}>
      <HalfAndHalfWrapper
        reversed={reversed === "Yes"}
        darkMode={darkModeBool}
        leftComponent={
          <ResponsiveImage
            inView={inView}
            css={imageStyle}
            src={image.url}
            alt={image?.alt}
          />
        }
        rightComponent={
          <>
            {icon && icon.url && (
              <div showOnDesktop={Boolean(icon)} css={iconWrapperStyle}>
                <ResponsiveImage
                  inView={inView}
                  css={iconStyle}
                  src={icon.url}
                  alt={icon?.alt}
                />
              </div>
            )}
            {headingLabel && (
              <p darkMode={darkModeBool} css={headingLabelStyle}>
                {headingLabel}
              </p>
            )}
            <h3 css={headingStyle} darkMode={darkModeBool}>
              {heading}
            </h3>
            {multipleParagraphs.map(({ text }, index) => (
              <p css={paragraphStyle} darkMode={darkModeBool} key={index}>
                {text}
              </p>
            ))}
            {tickList &&
              tickList.map(({ text }, index) => (
                <span css={tickListItem} darkMode={darkModeBool} key={index}>
                  <FontAwesomeIcon icon={faCheck} css={tick} />
                  {text}
                </span>
              ))}
            {buttonText && buttonLink && (
              <ButtonLink
                type={buttonStyle}
                to={buttonLink?.url}
                css={buttonStyles}
                target={buttonLink?.target}
              >
                {buttonText}
              </ButtonLink>
            )}
          </>
        }
      />
    </div>
  );
};
